import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { WhatNext, ProductBookCard, Row, Col } from '../components/global'

const ShopOwners = () => (
  <Layout>
    <SEO title="Shop Owners" />
    <div className="container">
      <h1>
        For <br />
        Shop Owners
      </h1>
      <p className="boost">
        Auto/Truck Repair Shop Owners - Fleet Service Managers - Dealership Service Managers
      </p>
      <p>
        No need to send techs away for critical electrical training at great expense and lose their
        productive shop time! You can provide your auto/truck service technicians with
        electrical-electronics troubleshooting training while they're working in the shop using our
        training programs.
      </p>
      <p>
        Enjoy immediate payoff and see instant results as techs begin to use this troubleshooting
        training on the very next electrical work order.
      </p>
      <p>
        <a href="/Brochure_Retail_SP_2019.pdf" target="_blank" rel="noopener noreferrer">
          Click here for a PDF of our full training programs.
        </a>
      </p>
      <p className="boost">There are 3 main products we recommend for Shop Owners:</p>
      <hr />
      <Row>
        <Col width="30%" flexGrow={true}>
          <ProductBookCard
            title="<em>SHORTCUTS</em>"
            step="Step 1"
            back={`Electrical SHORTCUTS" shows a technician how to troubleshoot vehicle electrical circuits on-the-vehicle with just a DMM and Current Clamp.`}
            productLink="/products/shortcuts"
            price={98.0}
          />
        </Col>
        <Col width="30%" flexGrow={true}>
          <ProductBookCard
            title="<em>FIRST THINGS FIRST</em>"
            step="Step 2"
            back={`The flip-chart guides a technician through quick electrical tests of a 14V electrical system with one battery in about 5 minutes.`}
            productLink="/products/first-things-first"
            price={89.0}
          />
        </Col>
        <Col width="30%" flexGrow={true}>
          <ProductBookCard
            title="The Starter Kit, H-111A"
            step="Step 3"
            back={`This circuit board will teach you the principles of testing and measuring live vehicle electrical circuits with your DMM.`}
            productLink="products/h-111a"
            price={279.0}
          />
        </Col>
      </Row>
      <hr />
    </div>
    <div className="container container-last">
      <WhatNext />
    </div>
  </Layout>
)

export default ShopOwners
